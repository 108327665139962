import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import { SelectedItemProvider } from "./views/dashboard/shared/SelectedItemContex";

// session pages
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/testregform")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
const QR = Loadable(lazy(() => import("app/views/sessions/Foundit")));
const Shipit = Loadable(lazy(() => import("app/views/sessions/Shipit")));

// Tabs
const AppEchart = Loadable(lazy(() => import("app/views/dashboard/tabs")));

// dashboard page
const Inventory = Loadable(lazy(() => import("app/views/dashboard/Inventory")));

// FormDialog page
const FormDialog = Loadable(lazy(() => import("app/views/material-kit/dialog/FormDialog")));

//Checklist page
const ChecklistItems = Loadable(lazy(() => import("app/views/dashboard/Checklist")));

//Dashboard page
const DefaultStatisticsCard = Loadable(lazy(() => import("app/views/dashboard/shared/defaultstatisticscard")));

//Home page
const Home = Loadable(lazy(() => import("app/site/LandingPage")));

const routes = [
  {
    element: <MatxLayout />,
    children: [
      ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard/default",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <SelectedItemProvider>
              <Inventory />
            </SelectedItemProvider>
          </AuthGuard>
        ),
      },

      // e-chart route
      {
        path: "/dashboard/tabs",
        element: (
          <AuthGuard roles={authRoles.editor}>
            <AppEchart />
          </AuthGuard>
        ),
      },

      //checklist route
      {
        path: "/dashboard/Checklist",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <ChecklistItems />
          </AuthGuard>
        ),
      },

      // form dialog route
      {
        path: "/material-kit/dialog",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <FormDialog />
          </AuthGuard>
        ),
      },

      // Dashboard route
      {
        path: "/dashboard/Dashboard",
        element: (
          <AuthGuard roles={authRoles.admin}>
            <DefaultStatisticsCard />
          </AuthGuard>
        ),
      },
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/signin", element: <JwtLogin /> },
  { path: "/signup", element: <JwtRegister /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/qr", element: <QR /> },
  { path: "/shipit", element: <Shipit /> },
  { path: "/home", element: <Home /> },
  { path: "/", element: <Navigate to="/home" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
