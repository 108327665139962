// SelectedItemContext.js
import React, { createContext, useContext, useState } from "react";
import useAuth from "app/hooks/useAuth";
import axios from "axios";

const SelectedItemContext = createContext();

export const useSelectedItem = () => useContext(SelectedItemContext);

export const SelectedItemProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [inventoryItems, setInventoryItems] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URI;

  const { user } = useAuth();

  const fetchInventoryItems = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.get(
        `${backendURL}/inventory?userId=${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setInventoryItems(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch inventory items", error);
    }
  };

  return (
    <SelectedItemContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        inventoryItems,
        setInventoryItems,
        fetchInventoryItems,
      }}
    >
      {children}
    </SelectedItemContext.Provider>
  );
};
